import type React from 'react'
import type { LinkTypes, SpecificMapWithDefault } from '../../types'
import type { I18nextKey, BaseLocalizedProps } from './types'

import Image from '../NextImage'
import { useRouter } from 'next/router'

import styled from '@emotion/styled'

import persistQueriesLink from '../../hocs/persist-queries-link'

import withDefault from '../../util/with-default'

import {
  APP_LINKS_BY_LOCALE,
  CHROME_PATHNAME,
  EDGE_PATHNAME,
  GOOGLE_DOCS_PATHNAME,
  MOBILE_WIDTH_PX,
  MS_WORD_PATHNAME
} from '../../constants'

const Container = styled.div`
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: center;
`

const Group = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    width: 100%;
    justify-content: space-evenly;
  }
`

const ImageContainer = styled.div`
  width: 75px;
  height: 75px;
  margin-bottom: 20px;
  position: relative;

  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    width: 48px;
    height: 48px;
    margin-bottom: 10px;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px;

  width: 112px;

  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    width: fit-content;
    margin: 16px 8px;
  }
`

const StyledLink = persistQueriesLink(
  styled(Wrapper)`
    text-decoration: none;
    color: ${(props): string => props.theme.outwrite.colors.black};

    transition: transform ${(props): string => props.theme.transitionDuration};
    &:hover {
      transform: scale(1.1);
    }
  `.withComponent('a')
)

const Text = styled.span`
  font-size: 18px;
  line-height: 24px;
  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    display: none;
  }
`

type Platform = 'facebook' | 'gmail' | 'google-docs' | 'linkedin' | 'outlook' | 'word' | 'wordpress' | 'chrome' | 'edge' | 'web-app'

interface PlatformDescription {
  src: string
  nameKey: I18nextKey
  // { pathname: { locale: link }}
  link?: SpecificMapWithDefault<string, SpecificMapWithDefault<string, LinkTypes | undefined>>
}

const CHROME_EXT_WEBSITE_LINKS: SpecificMapWithDefault<
string,
SpecificMapWithDefault<string, LinkTypes | undefined>
> = {
  [CHROME_PATHNAME]: { '*': undefined }, // Dont need to relink to same page
  [EDGE_PATHNAME]: { '*': undefined }, // If on edge page, dont need to link to /chrome
  '*': { '*': { href: CHROME_PATHNAME } }
}

const PLATFORM_LOGO: Record<Platform, PlatformDescription> = {
  facebook: {
    src: require('../../../public/assets/platform-logos/facebook.png'),
    nameKey: 'platforms.FACEBOOK',
    link: CHROME_EXT_WEBSITE_LINKS
  },
  gmail: {
    src: require('../../../public/assets/platform-logos/gmail.png'),
    nameKey: 'platforms.GMAIL',
    link: CHROME_EXT_WEBSITE_LINKS
  },
  'google-docs': {
    src: require('../../../public/assets/platform-logos/google-docs.svg'),
    nameKey: 'platforms.GOOGLE_DOCS',
    link: { '*': { '*': { href: GOOGLE_DOCS_PATHNAME } } }
  },
  linkedin: {
    src: require('../../../public/assets/platform-logos/linkedin.png'),
    nameKey: 'platforms.LINKEDIN',
    link: CHROME_EXT_WEBSITE_LINKS
  },
  outlook: {
    src: require('../../../public/assets/platform-logos/outlook.png'),
    nameKey: 'platforms.OUTLOOK',
    link: CHROME_EXT_WEBSITE_LINKS
  },
  word: {
    src: require('../../../public/assets/platform-logos/word.svg'),
    nameKey: 'platforms.MS_WORD',
    link: { '*': { '*': { href: MS_WORD_PATHNAME } } }
  },
  wordpress: {
    src: require('../../../public/assets/platform-logos/wordpress.png'),
    nameKey: 'platforms.WORDPRESS',
    link: CHROME_EXT_WEBSITE_LINKS
  },
  chrome: {
    src: require('../../../public/assets/platform-logos/chrome.svg'),
    nameKey: 'platforms.CHROME',
    link: CHROME_EXT_WEBSITE_LINKS
  },
  edge: {
    src: require('../../../public/assets/platform-logos/edge.png'),
    nameKey: 'platforms.EDGE',
    link: { '*': { '*': { href: EDGE_PATHNAME } } }
  },
  'web-app': {
    src: require('../../../public/assets/logos/mark.svg'),
    nameKey: 'platforms.WEB_APP',
    link: { '*': APP_LINKS_BY_LOCALE }
  }
}

interface Props extends BaseLocalizedProps {
  readonly platforms: Platform[]
}

const PlatformLogos: React.FC<Props> = ({ platforms, t, tOptions }) => {
  const router = useRouter()

  const nCols = platforms.length % 3 === 0 ? 3 : 2
  const nRows = Math.ceil(platforms.length / nCols)

  return (
    <Container>
      {Array.from({ length: nRows }).map((_, i) => (
        <Group key={i}>
          {Array.from({ length: nCols }).map((_, j) => {
            const p = platforms[i * nCols + j]
            const attrs = PLATFORM_LOGO[p]
            if (!attrs) return null
            const children = (
              <>
                <ImageContainer>
                  <Image src={attrs.src} alt='' objectFit='contain' layout='fill' />
                </ImageContainer>
                <Text>{t(attrs.nameKey, tOptions)}</Text>
              </>
            )
            const link = attrs.link && withDefault(
              withDefault(attrs.link, router.pathname),
              router.locale
            )
            return (
              !link
                ? <Wrapper key={p}>{children}</Wrapper>
                : <StyledLink key={p} {...link} >{children}</StyledLink>
            )
          })}
        </Group>
      ))}
    </Container>
  )
}

export default PlatformLogos
